<template>
  <a-modal v-model="visible" :title="(type === 'create' ? '创建' : '修改') + '公司'" @ok="save" width="700px">
    <a-form-model layout="vertical" ref="form" :model="form" class="edp-form">
      <div class="line-2">
        <a-form-model-item label="公司名称" prop="company_name">
          <a-input v-model="form.company_name"></a-input>
        </a-form-model-item>
        <a-form-model-item label="供应商编号" prop="company_no">
          <a-input v-model="form.company_no"></a-input>
        </a-form-model-item>
        <a-form-model-item label="公司角色" prop="company_role">
          <a-select v-model="form.company_role">
            <a-select-option v-for="item in cat.company_role" :key="item.cat_code" :value="item.cat_code">{{item.cat_name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="国家" prop="country_code">
          <a-select v-model="form.country_code">
            <a-select-option v-for="item in cat.country" :key="item.cat_code" :value="item.cat_code">{{item.cat_name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="联系人" prop="contactor_name">
          <a-input v-model="form.contactor_name"></a-input>
        </a-form-model-item>
        <a-form-model-item label="联系方式" prop="contactor_mobile">
          <a-input v-model="form.contactor_mobile"></a-input>
        </a-form-model-item>
      </div>
    </a-form-model>
  </a-modal>
</template>

<script>
import catMixin from "@/utils/mixins/catMixin.js";
import {fetch} from "@/utils/request.js";

export default {
  name: "companyModal",
  mixins: [catMixin],
  data() {
    return {
      type: "create",
      visible: false,
      loading: false,
      cat: {
        company_role: [],
        country: [],
      },
      form: {
        contactor_mobile: "",
        company_no: "",
        contactor_name: "",
        country_code: "",
        company_name: "",
        company_role: ""
      },
      backups: {}
    }
  },
  created() {
    this.backups = JSON.parse(JSON.stringify(this.form))
  },
  methods: {
    open(type, oid) {
      this.type = type
      this.visible = true
      if (type === 'create') {
        this.form = JSON.parse(JSON.stringify(this.backups))
      } else {
        this.form = Object.assign({}, JSON.parse(JSON.stringify(this.backups)), {oid})
        this.getDetail()
      }
    },
    getDetail() {
      this.loading = true
      fetch("post", "/company/info", {
        oid: this.form.oid
      }).then(res => {
        if (res.status === 0) {
          this.form = res.data
        }
      }).finally(() => {
        this.loading=false
      })
    },
    save() {
      let url = "/company/create"
      if (this.type === "update") {
        url = "/company/update"
      }
      delete this.form.contactor_email
      fetch("post", url, this.form).then(res => {
        if (res.status === 0) {
          this.$message.success("保存成功")
          this.visible = false
          this.$emit("reload")
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>

</style>
