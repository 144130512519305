<template>
  <div class="app-main-content">
    <div class="container d-flex">
      <settings-left-menu></settings-left-menu>
      <card title="供应商列表" style="width: calc(100% - 326px)">
        <template #tools>
          <div class="item">
            <a-button class="search" @click="$refs.companyModal.open('create')">创建供应商</a-button>
          </div>
          <div class="right">
            <div class="item">
              <span class="label">搜索</span>
              <a-input class="search-input" v-model="listQuery.keywords" placeholder="请输入公司名称">
                <a-icon slot="prefix" type="search" />
              </a-input>
            </div>
            <div class="item">
              <a-button class="search" @click="getList">查询</a-button>
            </div>
            <div class="item">
              <a-dropdown>
                <img class="more" :src="$toUrl('more.png')">
                <a-menu slot="overlay" @click="selectActionMenu">
                  <a-menu-item key="download">模版</a-menu-item>
                  <a-menu-item key="import">导入</a-menu-item>
                  <a-menu-item key="export">导出</a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </div>
        </template>
        <div style="padding: 0 20px">
          <a-table
              :data-source="list"
              :pagination="pagination"
              @change="paginationChange"
              rowKey="oid"
          >
            <a-table-column title="公司名称" data-index="company_name"></a-table-column>
            <a-table-column title="角色" data-index="company_role">
              <template #default="_, row">
                <span>{{ getCatNameByCode(row.company_role, 'company_role') }}</span>
              </template>
            </a-table-column>
            <a-table-column title="国家" data-index="country_code">
              <template #default="_, row">
                <span>{{ getCatNameByCode(row.country_code, 'country') }}</span>
              </template>
            </a-table-column>
            <a-table-column title="联系人" data-index="contactor_name"></a-table-column>
            <a-table-column title="联系电话" data-index="contactor_mobile"></a-table-column>
            <a-table-column title="日期" data-index="create_time"></a-table-column>
            <a-table-column title="操作" width="200px">
              <template #default="_, row">
                <div>
                  <a-button style="padding: 0;margin-right: 7px" type="link" @click="$router.push({name:'personnel',params:{company:row.company_name}})">公司账户</a-button>
                  <a-button style="padding: 0;margin-right: 7px" type="link"
                            @click="$refs.companyModal.open('update', row.oid)">修改
                  </a-button>
                  <a-button style="padding: 0;" type="link" @click="deleteCompany(row)">删除</a-button>
                </div>
              </template>
            </a-table-column>
          </a-table>
        </div>
      </card>
    </div>
    <company-modal @reload="getList" ref="companyModal"></company-modal>
    <import-file url="/company/import" ref="import" @response="importSuccess"></import-file>
  </div>
</template>

<script>
import listMixin from "@/utils/mixins/listMixin.js";
import CompanyModal from "@/components/companyModal.vue";
import {fetch} from "@/utils/request.js";
import catMixin from "@/utils/mixins/catMixin.js";
import ImportFile from "@/components/importFile.vue";
import settingsLeftMenu from "@/components/settingsLeftMenu.vue";
import {getTemplate} from "@/utils/helpFunc";

export default {
  components: { ImportFile, CompanyModal, settingsLeftMenu },
  mixins: [listMixin, catMixin],
  data() {
    return {
      cat: {
        company_role: [],
        country: []
      },
      listMixinOptions: {
        url: "/company/retrieve",
      },
      listQuery: {
        keywords: ''
      }
    }
  },
  methods: {
    selectActionMenu({ key }) {
      if (key === "import") {
        this.$refs.import.upload()
      } else if (key === "export") {
        window.open(this.$toUrl(`/company/export?oids=${this.list.map(item => item.oid).join(',')}`, true, "api"))
      } else if (key === "download") {
        getTemplate('/attachment/model', '公司导入模版.xlsx')
      }
    },
    importSuccess(res) {
      if (res.status === 0) {
        this.$message.success("导入成功")
        this.getList()
      } else {
        this.$message.error(res.message)
      }
    },
    deleteCompany(row) {
      this.$confirm({
        title: `确定要删除公司 ${row.company_name}吗?`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          fetch('post', '/company/delete', {
            oid: row.oid,
          }).then((res) => {
            if (res.status === 0) {
              this.$message.success('删除成功！')
              this.getList()
            } else {
              this.$message.error(res.message)
            }
          })
        },
        onCancel() {
          console.log('Cancel');
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;

  /deep/ .edp-card-body {
    overflow-y: auto;
  }
}

.action {
  flex-shrink: 0;
  padding: 10px 20px;
  display: flex;
  align-items: center;

  /deep/ .right-action {
    display: flex;
    margin-left: auto;

    & > .ant-btn {
      margin-right: 26px;
    }

    .form-item {
      display: flex;
      align-items: center;
      margin-bottom: 0;
    }
  }
}
</style>
